import HotelRoom from '../../components/views/hotel-room/hotel-room';
import { hotelRooms as hotelRoomContent } from '../../helpers/content';
import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

const IndexPage = () => {
    const luxuryHotel = hotelRoomContent.luxuryHotel;

    return (
    <Layout>
        <SEO title="Luxury Hotel Room Layout" />
        <HotelRoom hotelRoomContent={luxuryHotel} />
    </Layout>
    )
}

export default IndexPage;